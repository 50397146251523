import '../shared/nav/collapsing_nav'
import '../shared/phone'
import '../shared/buyers_page_personalizer'
import '../shared/seating_chart_filter_without_svg'

import AddToCartForms from '../shared/add_to_cart_forms'
import HotelIndex from '../shared/add_to_cart_forms/h4h_hotels/hotel_index'
import { store } from'../default/store'
import VueLoader from '../default/vue-loader'
import Checkout from '../shared/checkout'
import DepositRedemptions from '../shared/deposit_redemptions'
import CartNav from '../shared/nav/nav_cart'
import AddToCart from '../full-gpe/cart/add-to-cart'

document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  AddToCart.AddToCart()

  VueLoader.loadVueComponent(HotelIndex, document.getElementById('hotelIndex'), store)

})
